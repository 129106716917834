import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import { Row, Col } from 'styled-bootstrap-grid';
import ContentContainer from '../components/global/contentContainer';

import Title from '../components/text/title';
import Alert from '../components/text/alert';
import { Line } from '../components/utility/line';

import styled from 'styled-components';
import GoogleMap from '../components/utility/googleMap';

const Theme = {
  highlightColor: '#d27dff',
  textColor: '#000000',
};

const ContainerTheme = {
  backgroundColor: '#dcbeff',
};

const ContactColumn = styled.div`
  font-size: 17px;

  & p:first-child {
    margin-top: 0;
  }

  &:last-of-type {
    margin: 0 0 40px 0;
  }

  & a {
    color: inherit;
  }
`;

const ContactForm = styled.div`
  height: auto;

  & p {
    margin: 0;
    padding: 18px;
    width: 100%;

    border: 2px solid #d27dff;
    background-color: #fff;

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
  }
  & p:nth-child(2) {
    border-bottom: 0;
  }
  & p:nth-child(3) {
    border-bottom: 0;
  }
  & p:nth-child(5) {
    padding: 0;
    margin-top: 17px;

    border: 0;
    background-color: #dcbeff;
  }
  & label {
    color: #d27dff;
  }
  & input,
  textarea {
    margin-left: 18px;
    width: calc(100% - 65px - 18px);
    border: 0;
    -webkit-appearance: none;
    padding: 0;
    &:focus {
      outline: none;
    }
  }

  & input {
    height: 22px;
    position: relative;
    line-height: 1;
  }
  & button {
    display: flex;
    justify-content: space-between;

    padding: 17px;
    margin: 0;

    width: 100%;

    border: 0;
    -webkit-appearance: none;

    background-color: #000;
    color: #fff;

    cursor: pointer;
  }
`;

const ContactHeader = styled.div`
  background-color: #d27dff;

  color: #fff;
  text-align: center;
  font-size: 20px;

  padding: 17px;
`;

const Address = styled.h2`
  font-size: 20px;
`;

const MailchimpTitle = styled.p`
  color: #d27dff;
  text-align: center;
  font-size: 17px;

  padding: 38px 0 18px 0;
`;

const ThankYouMessage = styled.p`
  color: #000;
  text-align: left;
  font-size: 17px;

  padding: 18px 0;
`;

const Arrow = styled.span`
  line-height: 14px;
`;

const Thankyou = ({ data }) => {
  return (
    <>
      <Helmet title={data.prismicContact.data.title.text} />
      <Row css={ContainerTheme} justifyContent="center">
        <Col col={12}>
          <ContentContainer padding={'0 36px'}>
            <Title
              margin={'80px 0 36px 0'}
              text={data.prismicContact.data.title.text}
              case="inherit"
            />
          </ContentContainer>
        </Col>

        <Col col={12}>
          <ContentContainer padding={'0 36px'} mobilePadding={'0 9px'}>
            <Alert
              margin={'0 0 36px 0'}
              text={data.prismicContact.data.text.html}
              case="inherit"
            />
          </ContentContainer>
        </Col>

        <Col col={12}>
          <Line
            margin={'0 36px 54px 36px'}
            mobileMargin={'0 9px'}
            color={Theme.highlightColor}
          />
        </Col>

        <Col col={12}>
          <ContentContainer padding={'0 36px'} mobilePadding={'40px 9px 0'}>
            <Row>
              <Col col={12} md={3}>
                <ContactColumn
                  dangerouslySetInnerHTML={{
                    __html: data.prismicContact.data.get_in_touch_text.html,
                  }}
                />
              </Col>

              <Col col={12} md={6}>
                <ContactForm>
                  <ContactHeader>CONTACT US</ContactHeader>
                  <form
                    name="contact"
                    method="POST"
                    data-netlify="true"
                    action="/thankyou"
                  >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact" />
                    <p>
                      <label>Subject</label>
                      <input
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        required
                      />
                    </p>
                    <p>
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        required
                      />
                    </p>
                    <p>
                      <label>Message</label>
                      <textarea
                        name="message"
                        rows="7"
                        placeholder="Message"
                        required
                      />
                    </p>
                    <p>
                      <button type="submit">
                        SEND MESSAGE <Arrow>→</Arrow>
                      </button>
                    </p>
                  </form>
                </ContactForm>

                <ThankYouMessage>
                  Thanks for contacting us, we'll be in touch soon.
                </ThankYouMessage>

                <MailchimpTitle>
                  {data.prismicContact.data.mailchimp_signup_text}
                </MailchimpTitle>
                <ContactForm>
                  <ContactHeader>NEWSLETTER SIGN-UP</ContactHeader>
                </ContactForm>
              </Col>

              <Col col={12} md={3}>
                <ContactColumn
                  dangerouslySetInnerHTML={{
                    __html: data.prismicContact.data.social_media_text.html,
                  }}
                />
              </Col>
            </Row>
          </ContentContainer>
        </Col>

        <Col col={12}>
          <Line
            margin={'75px 36px 30px 36px'}
            mobileMargin={'0 9px'}
            color={Theme.highlightColor}
          />
        </Col>

        <Col col={12}>
          <ContentContainer padding={'0 36px'}>
            <Address>{data.prismicContact.data.main_studio_text}</Address>
          </ContentContainer>
        </Col>

        <Col col={12}>
          <ContentContainer padding={'28px 36px 54px'} mobilePadding={'54px 0'}>
            <GoogleMap />
          </ContentContainer>
        </Col>
      </Row>
    </>
  );
};

export const query = graphql`
  query Thankyou {
    prismicContact {
      data {
        title {
          text
        }
        text {
          html
        }
        get_in_touch_text {
          html
        }
        social_media_text {
          html
        }
        mailchimp_signup_text
        main_studio_text
      }
    }
  }
`;

export default Thankyou;
